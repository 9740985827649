.cookies-container {
  min-height: 100vh;
  background: var(--background-primary);
  padding: 120px 10% 80px;
}

.cookies-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.cookies-content h1 {
  font-size: 3.5rem;
  color: var(--text-primary);
  margin-bottom: 2rem;
}

.cookie-message {
  margin-bottom: 4rem;
}

.cookie-message h2 {
  font-size: 2rem;
  color: var(--accent-primary);
  margin-bottom: 1rem;
}

.cookie-message p {
  font-size: 1.2rem;
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.cookie-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.feature-box {
  background: var(--card-background);
  padding: 2rem;
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.feature-box:hover {
  transform: var(--card-hover-transform);
  box-shadow: var(--shadow-lg);
}

.feature-box h3 {
  color: var(--accent-primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature-box p {
  color: var(--text-secondary);
  line-height: 1.6;
}

.cookie-footer {
  margin-top: 4rem;
  font-size: 1.2rem;
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .cookies-container {
    padding: 80px 5% 40px;
  }

  .cookies-content h1 {
    font-size: 2.5rem;
  }

  .cookie-message h2 {
    font-size: 1.8rem;
  }

  .cookie-message p {
    font-size: 1.1rem;
  }
} 