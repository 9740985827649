.landing-container {
  min-height: 100vh;
  background: var(--background-primary);
}

.hero-section {
  padding: 70px 10% 100px;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 50px 50px;
}

.hero-section h1 {
  font-size: 4.5rem;
  line-height: 1.1;
  margin-bottom: 1.8rem;
  font-weight: 900;
  color: var(--text-primary);
  letter-spacing: -0.02em;
  animation: fadeInUp 0.8s ease-out;
}

.highlight {
  color: var(--accent-primary);
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: 0.2em;
  left: 0;
  width: 100%;
  height: 0.2em;
  background: var(--shadow-accent);
  z-index: -1;
  transform: skew(-12deg);
}

.hero-text {
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--text-secondary);
  max-width: 650px;
  margin-bottom: 3rem;
  animation: fadeInUp 0.8s ease-out 0.2s backwards;
}

.hero-section .cta-buttons {
  display: flex;
  gap: 1.2rem;
  animation: fadeInUp 0.8s ease-out 0.4s backwards;
  width: fit-content;
}

.hero-section .primary-cta, .hero-section .secondary-cta {
  padding: 1.2rem 2.5rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: var(--shadow-accent);
}

.primary-cta {
  background: var(--accent-gradient);
  color: var(--text-on-primary);
}

.primary-cta:hover {
  color: var(--text-on-primary);
  transform: translateY(-3px);
}

.secondary-cta {
  border: 2px solid var(--accent-primary);
  color: var(--accent-primary);
  background: var(--backdrop-filter);
  backdrop-filter: var(--backdrop-blur);
}

.secondary-cta:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-accent);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 80px 10%;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background: var(--background-secondary);
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.feature-card:hover {
  transform: var(--card-hover-transform);
  box-shadow: var(--shadow-lg);
}

.feature-card h3 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.feature-card p {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.learn-more {
  color: var(--accent-primary);
  text-decoration: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.learn-more:hover {
  color: var(--accent-secondary);
}

.about-section {
  background: var(--background-secondary);
  padding: 100px 10%;
  text-align: center;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-content h2 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

.about-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .hero-section {
    padding: 60px 5% 80px;
    border-radius: 0 0 30px 30px;
  }

  .hero-section h1 {
    font-size: 2.8rem;
  }
  
  .hero-text {
    font-size: 1.3rem;
  }
  
  .hero-section .cta-buttons {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
  
  .hero-section .primary-cta, .hero-section .secondary-cta {
    width: auto;
    text-align: center;
    display: block;
  }
}
.blog-preview-section {
  padding: 4rem 2rem;
  padding: 80px 10%;
  background: var(--background-primary);
  max-width: 1200px;
  margin: 0 auto;
}

.blog-preview-section h2 {
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .blog-preview-section {
    padding: 4rem 2rem;
  }
}

.blog-preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.blog-preview-card {
  background: var(--card-background);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: transform 0.3s ease;
  margin: 0 auto;
}

.blog-preview-card:hover {
  transform: var(--card-hover-transform);
  box-shadow: var(--shadow-md);
}

.blog-preview-image {
  height: 200px;
  overflow: hidden;
}

.blog-preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-preview-content {
  padding: 1.5rem;
}

.blog-preview-category {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background: var(--accent-primary);
  color: var(--text-on-primary);
  border-radius: 20px;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.blog-preview-content h3 {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  color: var(--text-quaternary);
}

.blog-preview-content p {
  color: var(--text-quinary);
  margin-bottom: 1rem;
}

.blog-preview-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid var(--border-color-secondary);
  padding-top: 1rem;
}

.blog-preview-date {
  color: var(--text-tertiary);
  font-size: 0.875rem;
}

.blog-preview-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--accent-primary);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.blog-preview-link:hover {
  color: var(--accent-secondary);
}

.blog-preview-cta {
  text-align: center;
  margin-top: 2rem;
}

.products-section {
  padding: 80px 10%;
  max-width: 1200px;
  margin: 0 auto;
}

.products-section h2 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 2rem;
  text-align: center;
}

.product-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.product-card {
  background: var(--background-secondary);
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.product-card:hover {
  transform: var(--card-hover-transform);
  box-shadow: var(--shadow-lg);
}

.product-card h3 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.product-card p {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.product-card.future {
  background: var(--background-quaternary);
  border: 2px dashed var(--border-color);
}

.bl-cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background: var(--accent-primary);
  color: var(--text-on-primary);
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.bl-cta-button:hover {
  background: var(--accent-secondary);
  transform: translateY(-2px);
  color: var(--text-on-primary);
}

.values-section {
  padding: 80px 10%;
  background: var(--background-tertiary);
}

.values-section h2 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 2rem;
  text-align: center;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.value-card {
  background: var(--background-secondary);
  padding: 2rem;
  border-radius: 16px;
  text-align: center;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.value-card:hover {
  transform: var(--card-hover-transform);
  box-shadow: var(--shadow-lg);
}

.value-card h3 {
  font-size: 1.5rem;
  color: var(--accent-primary);
  margin-bottom: 1rem;
}

.value-card p {
  color: var(--text-secondary);
  line-height: 1.6;
}

.product-card.research {
  background: linear-gradient(145deg, var(--background-secondary), var(--background-tertiary));
  border: none;
}

.about-section {
  background: var(--accent-gradient);
  color: var(--text-on-primary);
  padding: 100px 10%;
  text-align: center;
}

.about-content h2 {
  color: var(--text-on-primary);
}

.about-content p {
  color: var(--text-on-primary);
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .values-grid {
    grid-template-columns: 1fr;
  }
  
  .value-card {
    padding: 1.5rem;
  }
}