.footer {
    background-color: var(--background-primary);
    color: var(--text-primary);
    padding: 4rem 0 0;
    font-family: 'Inter', sans-serif;
    border-top: 1px solid var(--border-color);
}

.footer-content {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 4rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.footer-section.brand {
    max-width: 300px;
}

.brand-description {
    margin: 1rem 0;
    color: var(--text-secondary);
    line-height: 1.6;
}

.social-links {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.social-links a {
    color: var(--text-secondary);
    font-size: 1.5rem;
    transition: all 0.3s ease;
}

.social-links a:hover {
    color: var(--accent-primary);
    transform: translateY(-2px);
}

.footer-section h4 {
    color: var(--text-primary);
    margin-bottom: 1.2rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 0.8rem;
}

.footer-section ul li a {
    color: var(--text-secondary);
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 0.95rem;
}

.footer-section ul li a:hover {
    color: var(--accent-primary);
    padding-left: 5px;
}

.footerlogo {
    width: 100px;
    height: auto;
}

.footer-bottom {
    margin-top: 4rem;
    border-top: 1px solid var(--border-color);
    padding: 1.5rem 0;
    background-color: var(--background-main);
}

.footer-bottom-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: var(--text-tertiary);
}

.footer-bottom-links {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer-bottom-links a {
    color: var(--text-tertiary);
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-bottom-links a:hover {
    color: var(--accent-primary);
}

.separator {
    color: var(--text-tertiary);
}

@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .footer-section.brand {
        grid-column: 1 / -1;
        max-width: none;
        text-align: center;
    }

    .social-links {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .footer {
        padding: 2rem 0 0;
    }

    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
        gap: 1.5rem;
    }

    .footer-section {
        margin-bottom: 0;
    }

    .footer-bottom-content {
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
    }

    .footer-bottom-links {
        justify-content: center;
    }
}

.theme-toggle {
    background-color: var(--background-tertiary);
    border: 1px solid var(--border-color);
    border-radius: 20px;
    color: var(--text-secondary);
    cursor: pointer;
    font-family: inherit;
    font-size: 0.95rem;
    padding: 8px 16px;
    margin-top: 1rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;

}

@media (max-width: 768px) {
    .theme-toggle {
        margin: 0 auto;
        margin-top: 1rem;
    }
}

.theme-toggle:hover {
    background-color: var(--background-primary);
    color: var(--accent-primary);
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding-left: 16px;
}

.theme-toggle:active {
    transform: translateY(0);
}
