/* Base Styles */
.studybuddy-container {
    min-height: 100vh;
    background: var(--background-primary);
  }
  
  /* Hero Section */
  .studybuddy-hero {
    padding: 160px 10% 120px;
    margin: 0 auto;
    text-align: center;
    background: linear-gradient(160deg, var(--background-secondary) 0%, var(--background-tertiary) 100%);
    position: relative;
    overflow: hidden;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 30%, rgba(255,107,107,0.08) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(255,75,75,0.08) 0%, transparent 50%);
    z-index: 1;
    pointer-events: none;
  }
  
  .studybuddy-hero h1,
  .studybuddy-hero p,
  .studybuddy-hero .studybuddy-cta-buttons {
    position: relative;
    z-index: 2;
  }
  
  .studybuddy-hero h1 {
    font-size: 4.5rem;
    line-height: 1.1;
    margin-bottom: 1.5rem;
    font-weight: 800;
    background: var(--accent-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 15px rgba(255,107,107,0.2);
    transform: translateY(20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards;
  }
  
  .studybuddy-hero p {
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--text-secondary);
    max-width: 700px;
    margin: 0 auto 3rem;
    transform: translateY(20px);
    opacity: 0;
    animation: fadeInUp 0.8s ease forwards 0.2s;
  }
  
  /* Feature Grid */
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2.5rem;
    padding: 40px 5%;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .feature-card {
    background: var(--background-secondary);
    padding: 3rem 2.5rem;
    border-radius: 24px;
    box-shadow: var(--shadow-md);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
  }
  
  .feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: var(--accent-gradient);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.4s ease;
  }
  
  .feature-card:hover {

    box-shadow: var(--shadow-lg);
  }
  
  .feature-card:hover::before {
    transform: scaleX(1);
  }
  
  .feature-icon {
    background: linear-gradient(135deg, rgba(255,107,107,0.1), rgba(255,143,143,0.1));
    width: 64px;
    height: 64px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    position: relative;
    order: -1;
  }
  
  .feature-icon svg {
    color: var(--accent-primary);
    transition: transform 0.3s ease;
  }
  
  .feature-card:hover .feature-icon svg {
    transform: scale(1.1);
  }
  
  .feature-card h3 {
    font-size: 1.5rem;
    color: var(--text-primary);
    margin-bottom: 1rem;
    font-weight: 700;
  }
  
  .feature-card p {
    color: var(--text-tertiary);
    line-height: 1.7;
    font-size: 1.1rem;
  }
  
  /* Pricing Section */
  .pricing-section {
    background: var(--background-secondary);
    padding: 100px 10%;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .pricing-card {
    background: var(--background-tertiary);
    border-radius: 16px;
    padding: 2rem;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .pricing-card.featured {
    background: var(--background-secondary);
    box-shadow: var(--shadow-lg);
    transform: scale(1.05);
  }
  
  /* AI Demo Section */
  .ai-demo {
    background: linear-gradient(135deg, var(--background-primary) 0%, var(--background-secondary) 100%);
    padding: 80px 5%;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
  }
  
  .ai-demo h2 {
    font-size: 2.8rem;
    margin-bottom: 3rem;
    color: var(--text-primary);
    font-weight: 700;
    text-shadow: var(--shadow-sm);
    position: relative;
  }
  
  .ai-demo h2::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: var(--accent-primary);
    color: var(--text-on-primary);
    border-radius: 2px;
  }
  
  /* Demo Container and Input Styles */
  .demo-container {
    max-width: 800px;
    margin: 0 auto;
    background: var(--background-secondary);
    border-radius: 24px;
    padding: 2.5rem;
    box-shadow: var(--shadow-lg);
    backdrop-filter: var(--backdrop-blur);
    position: relative;
    z-index: 2;
    border: 1px solid var(--border-color);
  }
  
  .input-area.empty {
    width: 100%;
    position: relative;
    padding: 0;
  }
  
  .input-wrapper.empty {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--background-secondary);
    border-radius: 15px;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    transition: all 0.3s ease;
    margin: 0;
  }
  
  .chat-input.scrollable.empty {
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    font-size: 1rem;
    line-height: 1.5;
    min-height: 24px;
    max-height: 120px;
    resize: none;
    background: transparent;
    font-family: inherit;
    color: var(--text-primary);
  }
  
  .send-button {
    background: var(--accent-primary);
    color: var(--text-on-primary);
    border: none;
    padding: 0.8rem;
    border-radius: 12px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 12px;
    box-shadow: var(--shadow-accent);
  }
  
  .send-button:hover {
    background: var(--accent-secondary);
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
  }
  
  .send-button:active {
    transform: translateY(0);
  }
  
  .send-button:disabled {
    background: var(--background-tertiary);
    color: var(--text-tertiary);
    box-shadow: none;
    cursor: not-allowed;
    transform: none;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .ai-demo {
      padding: 40px 20px;
    }
  
    .ai-demo h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
  
    .demo-container {
      width: 95%;
      padding: 1rem;
    }
  
    .input-wrapper.empty {
      padding: 0.35rem;
    }
  
    .chat-input.scrollable.empty {
      font-size: 0.95rem;
      padding: 6px 10px;
    }
  }
  
  @media (max-width: 480px) {
    .ai-demo h2 {
      font-size: 1.75rem;
    }
  
    .demo-container {
      padding: 0.75rem;
    }
  
    .send-button {
      min-width: 34px;
      min-height: 34px;
      width: 34px;
      height: 34px;
    }
  }
  
  /* Comparison Table */
  .comparison-table {
    width: 100%;
    max-width: 1200px;
    margin: 4rem auto;
    border-collapse: collapse;
    background: var(--background-secondary);
    border-radius: 16px;
    overflow: hidden;
    box-shadow: var(--shadow-md);
  }
  
  .comparison-table th,
  .comparison-table td {
    padding: 1rem;
    text-align: center;
    border: 1px solid var(--border-color);
  }
  
  .comparison-table th {
    background: var(--background-tertiary);
    font-weight: 600;
    color: var(--text-primary);
  }
  
  /* CTA Buttons */
  .cta-button {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
    text-align: center;
  }
  
  .cta-primary {
    background: var(--accent-primary);
    color: var(--text-on-primary);
  }
  
  .cta-primary:hover {
    background: var(--accent-secondary);
    color: var(--text-on-primary);
    transform: translateY(-2px);
  }
  
  .cta-secondary {
    border: 2px solid var(--accent-primary);
    color: var(--accent-primary);
  }
  
  .cta-secondary:hover {
    background: rgba(255, 107, 107, 0.1);
    transform: translateY(-2px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .studybuddy-hero h1 {
      font-size: 2.5rem;
    }
    
    .studybuddy-hero p {
      font-size: 1.2rem;
    }
    
    .pricing-card.featured {
      transform: none;
    }
    
    .comparison-table {
      font-size: 0.9rem;
    }
  }
  
  /* Animation Keyframes */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .animate-in {
    animation: fadeIn 0.6s ease-out forwards;
  }

  /* Enhanced Base Styles */
  .section-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 4rem;
    color: var(--text-primary);
    position: relative;
  }

  .section-title::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: var(--accent-gradient);
    border-radius: 2px;
  }

  /* Enhanced Hero Section */
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 30%, rgba(255,107,107,0.08) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(255,75,75,0.08) 0%, transparent 50%);
    z-index: 1;
    pointer-events: none;
  }

  .gradient-text {
    background: var(--accent-gradient);
    color: var(--text-on-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .feature-section h2 {
    color: var(--text-primary);
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  /* Enhanced Feature Cards */
  .feature-card {
    position: relative;
    overflow: hidden;
  }

  .feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255,107,107,0.1) 0%, rgba(255,75,75,0.1) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .feature-card:hover::before {
    opacity: 1;
  }

  /* Enhanced Demo Section */
  .demo-background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      linear-gradient(45deg, var(--accent-primary) 25%, transparent 25%),
      linear-gradient(-45deg, var(--accent-primary) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, var(--accent-primary) 75%),
      linear-gradient(-45deg, transparent 75%, var(--accent-primary) 75%);
    background-size: 20px 20px;
    opacity: 0.03;
    animation: patternMove 20s linear infinite;
  }

  @keyframes patternMove {
    0% { background-position: 0 0; }
    100% { background-position: 40px 40px; }
  }

  /* Success Path Connector */
  .path-connector {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: var(--accent-primary);
    z-index: 0;
  }

  .path-step {
    position: relative;
    z-index: 1;
    background: var(--background-secondary);
  }

  /* Floating Shapes Animation */
  .floating-shapes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
  }

  .floating-shapes::before,
  .floating-shapes::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    background: rgba(255,107,107,0.1);
    animation: float 10s infinite linear;
  }

  @keyframes float {
    0% { transform: translate(0, 0) rotate(0deg); }
    100% { transform: translate(100px, 100px) rotate(360deg); }
  }

  /* Enhanced Animations */
  .animate-in {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.8s ease-out forwards;
  }

  /* Responsive Enhancements */
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem;
    }

    .path-connector {
      display: none;
    }

    .floating-shapes {
      display: none;
    }
  }

  /* Success Path Section */
  .success-path {
    padding: 100px 10%;
    position: relative;
    text-align: center;
    background: var(--background-secondary);
  }

  .success-path h2 {
    color: var(--text-primary);
  }

  .path-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 3rem auto 0;
    position: relative;
  }

  .path-step {
    padding: 2rem;
    background: var(--background-secondary);
    border-radius: 16px;
    box-shadow: var(--shadow-md);
    transition: all 0.3s ease;
  }

  .path-step:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }

  .step-number {
    width: 40px;
    height: 40px;
    background: var(--accent-primary);
    color: var(--text-on-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 0 auto 1rem;
  }

  .path-step h3 {
    color: var(--text-primary);
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }

  .path-step p {
    color: var(--text-secondary);
    font-size: 0.9rem;
    line-height: 1.5;
  }

  /* Final CTA Section */
  .final-cta {
    padding: 100px 10%;
    text-align: center;
    background: linear-gradient(160deg, var(--background-secondary) 0%, var(--background-tertiary) 100%);
    position: relative;
    overflow: hidden;
  }

  .final-cta h2 {
    font-size: 2.5rem;
    color: var(--text-primary);
    margin-bottom: 1rem;
    position: relative;
    z-index: 1;
  }

  .final-cta p {
    font-size: 1.25rem;
    color: var(--text-secondary);
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
  }

  .final-cta .cta-button {
    position: relative;
    z-index: 1;
  }

  .final-cta .cta-button svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }

  .final-cta .cta-button:hover svg {
    transform: translateX(5px);
  }

  /* Trust Indicators Section */
  .trust-indicators {
    padding: 60px 10%;
    background: var(--background-secondary);
  }

  .trust-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  .trust-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    color: var(--text-secondary);
    font-size: 1.1rem;
  }

  .trust-item svg {
    color: var(--accent-primary);
    font-size: 1.25rem;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .path-steps {
      grid-template-columns: 1fr;
      max-width: 400px;
    }

    .final-cta h2 {
      font-size: 2rem;
    }

    .final-cta p {
      font-size: 1.1rem;
    }

    .trust-grid {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }

    .trust-item {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 480px) {
    .trust-grid {
      grid-template-columns: 1fr;
    }
  }

  /* Update CTA Buttons */
  .studybuddy-cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
  }

  .studybuddy-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2.5rem;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.1rem;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;
    border: none;
  }

  .studybuddy-btn-primary {
    background: var(--accent-primary);
    color: var(--text-on-primary);
    box-shadow: var(--shadow-accent);
  }

  .studybuddy-btn-primary:hover {
    background: var(--accent-secondary);
    color: var(--text-on-primary);
    transform: translateY(-2px);
    box-shadow: var(--shadow-lg);
  }

  .studybuddy-btn-primary:active {
    transform: translateY(0);
    box-shadow: var(--shadow-md);
  }

  .studybuddy-btn-secondary {
    background: transparent;
    border: 2px solid var(--accent-primary);
    color: var(--accent-primary);
  }

  .studybuddy-btn-secondary:hover {
    background: rgba(255, 107, 107, 0.1);
    transform: translateY(-2px);
  }

  /* Update existing button references */
  .final-cta .studybuddy-btn svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }

  .final-cta .studybuddy-btn:hover svg {
    transform: translateX(5px);
  }

  @media (max-width: 768px) {
    .studybuddy-btn {
      padding: 0.875rem 2rem;
      font-size: 1rem;
    }
  }
  @media (max-width: 480px) {
    .demo-container{
      max-width: 80%;
    }
  }

  /* Study Spaces Showcase */
  .study-spaces-showcase {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 2rem;
  }

  .showcase-header {
    text-align: center;
    margin-bottom: 4rem;
  }

  .showcase-header h2 {
    font-size: 3rem;
    font-weight: 800;
    background: var(--accent-gradient);
    color: var(--text-on-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
  }

  .showcase-header p {
    font-size: 1.25rem;
    color: var(--text-tertiary);
    max-width: 600px;
    margin: 0 auto;
  }

  /* Feature Preview Window */
  .showcase-visual {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 4rem;
  }

  .feature-preview {
    background: var(--background-secondary);
    border-radius: 16px;
    box-shadow: var(--shadow-lg);
    padding: 1.75rem;
    overflow: hidden;
  }

  .space-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    margin-bottom: 2rem;
    position: relative;
    background: var(--background-secondary);
    border-radius: 16px;
    color: var(--text-primary);
  }

  .header-left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .return-to-chats {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    color: var(--text-tertiary);
    background: var(--background-tertiary);
    border: 1px solid var(--border-color);
    text-decoration: none;
  }

  .create-space-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    color: var(--text-on-primary);
    background: var(--accent-gradient);
    border: none;
    border-radius: 0.75rem;
    font-size: 0.95rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .space-preview-search {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .space-preview-search input {
    width: 100%;
    padding: 1rem 1rem 1rem 3rem;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    background: var(--background-secondary);
    font-size: 1rem;
    transition: all 0.3s ease;
  }

  .space-preview-search .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-tertiary);
  }

  /* Space Preview Grid */
  .space-preview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1.5rem;
    margin: 2rem auto 0;
    max-width: 1400px;
  }

  .space-preview-card {
    position: relative;
    padding: 1.75rem;
    border-radius: 16px;
    background: linear-gradient(145deg, var(--background-secondary), var(--background-tertiary));
    border: 1px solid var(--border-color);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: var(--shadow-md);
    animation: fadeInUp 0.6s ease forwards;
    animation-delay: calc(var(--index) * 0.1s);
  }

  .space-preview-card:hover {
    transform: translateY(-6px);
    box-shadow: var(--shadow-accent);
  }

  .space-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--text-primary);
  }

  .space-description {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin: 0;
  }

  .space-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }

  .space-stats {
    display: flex;
    gap: 1rem;
  }

  .stat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-tertiary);
    font-size: 0.85rem;
  }

  .arrow-icon {
    color: var(--text-tertiary);
    transition: transform 0.2s ease;
  }

  .space-preview-card:hover .arrow-icon {
    transform: translateX(4px);
    color: var(--accent-primary);
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .space-preview-grid {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      gap: 1rem;
    }

    .space-preview-card {
      height: auto;
      min-height: 120px;
      padding: 1.25rem;
    }

    .space-preview-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      padding: 1rem;
    }
  }

  @media (max-width: 480px) {
    .feature-preview {
      padding: 0.5rem;
    }
  }

  /* Study Spaces Features */
  .study-spaces-features {
    margin-top: 4rem;
  }

  .spaces-feature-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  .spaces-feature-column {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .spaces-feature-card {
    background: var(--background-secondary);
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    gap: 1.5rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--border-color);
    box-shadow: var(--shadow-md);
  }

  .spaces-feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: var(--accent-gradient);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  .spaces-feature-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }

  .spaces-feature-card:hover::before {
    transform: scaleX(1);
  }

  .spaces-feature-icon {
    background: linear-gradient(135deg, rgba(255, 107, 107, 0.1), rgba(255, 143, 143, 0.1));
    width: 56px;
    height: 56px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
  }

  .spaces-feature-icon svg {
    color: var(--accent-primary);
    transition: transform 0.3s ease;
  }

  .spaces-feature-card:hover .spaces-feature-icon svg {
    transform: scale(1.1);
  }

  .spaces-feature-icon::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(255, 107, 107, 0.1) 0%, transparent 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .spaces-feature-card:hover .spaces-feature-icon::after {
    opacity: 1;
  }

  .spaces-feature-content {
    flex: 1;
  }

  .spaces-feature-content h3 {
    color: var(--text-primary);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    position: relative;
  }

  .spaces-feature-content p {
    color: var(--text-tertiary);
    line-height: 1.6;
    font-size: 1rem;
  }

  /* Responsive Design */
  @media (max-width: 1024px) {
    .spaces-feature-columns {
      gap: 2rem;
      padding: 0 2rem;
    }
    
    .spaces-feature-card {
      padding: 1.75rem;
    }
  }

  @media (max-width: 768px) {
    .spaces-feature-columns {
      grid-template-columns: 1fr;
      max-width: 600px;
    }
    
    .spaces-feature-column {
      gap: 2rem;
    }
    
    .spaces-feature-card {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  @media (max-width: 480px) {
    .spaces-feature-card {
      flex-direction: column;
      text-align: center;
      padding: 1.5rem;
    }
    
    .spaces-feature-icon {
      margin: 0 auto 1rem;
    }
    
    .spaces-feature-content h3 {
      font-size: 1.2rem;
    }
    
    .spaces-feature-content p {
      font-size: 0.95rem;
    }
  }

  /* Animation for cards on scroll */
  .spaces-feature-card {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease forwards;
  }

  .spaces-feature-column:nth-child(2) .spaces-feature-card {
    animation-delay: 0.2s;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }