:root {
  /* Light theme colors */
  --background-primary: #FAFAFA;
  --background-secondary: #ffffff;
  --background-tertiary: #f8f9fa;
  --background-quaternary: #f5f5f5;
  
  /* Text colors */
  --text-primary: #1A1A1A;
  --text-secondary: #4A4A4A;
  --text-tertiary: #666666;
  --text-quaternary: #2c3e50;
  --text-quinary: #4a5568;
  --text-on-primary: #ffffff;
  
  /* Accent colors */
  --accent-primary: #FF6B6B;
  --accent-secondary: #FF4B4B;
  --accent-gradient: linear-gradient(135deg, #FF6B6B, #FF8B8B);
  --accent-gradient-hover: linear-gradient(135deg, #FF5B5B, #FF7B7B);
  
  /* Border & Shadow */
  --border-color: #eee;
  --border-color-secondary: #e2e8f0;
  --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 20px rgba(0, 0, 0, 0.05);
  --shadow-lg: 0 8px 30px rgba(0, 0, 0, 0.1);
  --shadow-accent: 0 4px 15px rgba(255, 107, 107, 0.2);
  
  /* Card & Surface */
  --card-background: #ffffff;
  --card-background-secondary: #f8f9fa;
  --card-hover-transform: translateY(-5px);
  
  /* Overlay & Backdrop */
  --overlay-color: rgba(0, 0, 0, 0.1);
  --backdrop-blur: blur(10px);
  --backdrop-filter: rgba(255, 255, 255, 0.8);
  
  /* Button */
  --button-background: #f5f5f5;
  --button-hover-background: #FF6B6B;
  --button-hover-color: #ffffff;
}

[data-theme='dark'] {
  /* Dark theme colors */
  --background-primary: #1a1a1a;
  --background-secondary: #2d2d2d;
  --background-tertiary: #333333;
  --background-quaternary: #404040;
  
  /* Text colors */
  --text-primary: #ffffff;
  --text-secondary: #e0e0e0;
  --text-tertiary: #b0b0b0;
  --text-quaternary: #ffffff;
  --text-quinary: #909090;
  
  /* Accent colors remain the same for brand consistency */
  --accent-primary: #FF6B6B;
  --accent-secondary: #FF4B4B;
  --accent-gradient: linear-gradient(135deg, #FF6B6B, #FF8B8B);
  --accent-gradient-hover: linear-gradient(135deg, #FF5B5B, #FF7B7B);
  
  /* Border & Shadow */
  --border-color: #404040;
  --border-color-secondary: #505050;
  --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.2);
  --shadow-md: 0 4px 20px rgba(0, 0, 0, 0.3);
  --shadow-lg: 0 8px 30px rgba(0, 0, 0, 0.4);
  --shadow-accent: 0 4px 15px rgba(255, 107, 107, 0.3);
  
  /* Card & Surface */
  --card-background: #2d2d2d;
  --card-background-secondary: #333333;
  --card-hover-transform: translateY(-5px);
  
  /* Overlay & Backdrop */
  --overlay-color: rgba(0, 0, 0, 0.4);
  --backdrop-blur: blur(10px);
  --backdrop-filter: rgba(45, 45, 45, 0.8);
  
  /* Button */
  --button-background: #404040;
  --button-hover-background: #FF6B6B;
  --button-hover-color: #ffffff;
}