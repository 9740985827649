/* Main Container */
.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Inter', sans-serif;
}

/* Hero Section */
.about-container .hero-section {
    text-align: center;
    padding: 4rem 2rem;
    margin-bottom: 4rem;
    background: linear-gradient(160deg, var(--background-light) 0%, var(--background-lighter) 100%);
    border-radius: 24px;
}

.about-container .hero-content h1 {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.about-container .hero-subtitle {
    font-size: 1.4rem;
    color: var(--text-secondary);
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.6;
}

/* Vision Section */
.about-container .vision-section {
    padding: 4rem 2rem;
    background: var(--background-primary);
    border-radius: 24px;
    margin-bottom: 4rem;
    box-shadow: 0 4px 20px var(--box-shadow);
}

.about-container .vision-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.about-container .vision-text {
    font-size: 1.2rem;
    line-height: 1.8;
    color: var(--text-tertiary);
}

/* Values Section */
.about-container .values-section {
    padding: 4rem 2rem;
    margin-bottom: 4rem;
    background: linear-gradient(160deg, var(--background-light) 0%, var(--background-lighter) 100%);
    border-radius: 24px;
}

.about-container .values-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 3rem;
}

.about-container .value-card {
    background: var(--background-primary);
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 20px var(--box-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-container .value-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px var(--box-shadow-hover);
}

.about-container .value-card h3 {
    color: var(--text-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.about-container .value-card p {
    color: var(--text-tertiary);
    line-height: 1.6;
}

/* Mission Section */
.about-container .mission-section {
    background: var(--accent-primary);
    color: var(--text-on-primary);
    padding: 4rem 2rem;
    border-radius: 24px;
    margin-bottom: 4rem;
}

.about-container .mission-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.about-container .mission-section h2 {
    color: var(--text-on-primary);
}

.about-container .mission-text {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-top: 1.5rem;
}

/* Approach Section */
.about-container .approach-section {
    padding: 4rem 2rem;
    margin-bottom: 4rem;
}

.about-container .approach-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 3rem;
}

.about-container .approach-card {
    background: var(--background-primary);
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 4px 20px var(--box-shadow);
    transition: transform 0.3s ease;
}

.about-container .approach-card:hover {
    transform: translateY(-5px);
}

.about-container .approach-card h3 {
    color: var(--text-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

.about-container .approach-card p {
    color: var(--text-tertiary);
    line-height: 1.6;
}

/* Future Section */
.about-container .future-section {
    padding: 4rem 2rem;
    background: var(--background-light);
    border-radius: 24px;
    margin-bottom: 4rem;
}

.about-container .future-text {
    font-size: 1.2rem;
    color: var(--text-tertiary);
    margin-bottom: 2rem;
    line-height: 1.6;
}

.about-container .future-list {
    list-style: none;
    padding: 0;
}

.about-container .future-list li {
    margin-bottom: 1rem;
    padding-left: 2rem;
    position: relative;
    color: var(--text-tertiary);
}

.about-container .future-list li::before {
    content: "→";
    position: absolute;
    left: 0;
    color: var(--accent-primary);
}

/* Join Section */
.about-container .join-section {
    text-align: center;
    padding: 4rem 2rem;
    background: var(--background-primary);
    border-radius: 24px;
    margin-bottom: 2rem;
}

.about-container .join-text {
    font-size: 1.2rem;
    color: var(--text-tertiary);
    max-width: 700px;
    margin: 0 auto 2rem;
    line-height: 1.6;
}

.about-container .cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
}

.about-container .cta-button {
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
}

.about-container .cta-button.primary {
    background: var(--accent-primary);
    color: var(--text-on-primary);
}

.about-container .cta-button.secondary {
    background: var(--background-primary);
    color: var(--accent-primary);
    border: 2px solid var(--accent-primary);
}

.about-container .cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px var(--box-shadow-hover);
}

/* Common Styles */
.about-container h2 {
    color: var(--text-primary);
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .about-container .hero-content h1 {
        font-size: 2.5rem;
    }

    .about-container .hero-subtitle {
        font-size: 1.2rem;
    }

    .about-container h2 {
        font-size: 2rem;
    }

    .about-container .values-grid,
    .about-container .approach-grid {
        grid-template-columns: 1fr;
    }

    .about-container {
        padding: 1rem;
    }

    .about-container .vision-section,
    .about-container .mission-section,
    .about-container .future-section,
    .about-container .join-section {
        padding: 3rem 1.5rem;
    }
}

/* Animation Classes */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.about-container .fade-in {
    animation: fadeIn 0.8s ease forwards;
}