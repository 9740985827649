.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
    font-family: 'Inter', sans-serif;
}

.contact-hero {
    text-align: center;
    margin-bottom: 3rem;
}

.contact-hero h1 {
    font-size: 2.5rem;
    color: var(--text-quaternary);
    margin-bottom: 1rem;
}

.contact-hero p {
    color: var(--text-tertiary);
    font-size: 1.1rem;
    max-width: 600px;
    margin: 0 auto;
}

.contact-categories {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 3rem;
    flex-wrap: wrap;
}

.category-btn {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 1.5rem;
    border: 2px solid var(--border-color);
    border-radius: 12px;
    background: var(--background-secondary);
    color: var(--text-quinary);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.category-btn svg {
    font-size: 1.2rem;
}

.category-btn:hover {
    border-color: var(--accent-primary);
    color: var(--accent-primary);
    transform: translateY(-2px);
}

.category-btn.active {
    background: var(--accent-primary);
    border-color: var(--accent-primary);
    color: var(--text-on-primary);
}

.contact-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 3rem;
    margin-top: 2rem;
}

.contact-info {
    padding: 2rem;
}

.info-card {
    background: var(--background-secondary);
    padding: 2rem;
    border-radius: 16px;
    box-shadow: var(--shadow-md);
    text-align: center;
}

.info-card svg {
    font-size: 2.5rem;
    color: var(--accent-primary);
    margin-bottom: 1rem;
}

.info-card h3 {
    color: var(--text-quaternary);
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.info-card p {
    color: var(--accent-primary);
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.info-card small {
    color: var(--text-tertiary);
    font-size: 0.9rem;
}

.contact-form {
    background: var(--background-secondary);
    padding: 2.5rem;
    border-radius: 16px;
    box-shadow: var(--shadow-md);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
    width: 92%;
    padding: 1rem;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    font-size: 1rem;
    color: var(--text-quaternary);
    transition: all 0.3s ease;
    background: var(--background-light);
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: var(--accent-primary);
    outline: none;
    box-shadow: var(--shadow-accent);
}

.form-group textarea {
    min-height: 150px;
}

.submit-btn {
    width: 100%;
    padding: 1rem;
    background: var(--accent-primary);
    color: var(--text-on-primary);
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-btn:hover:not(:disabled) {
    background: var(--accent-secondary);
    transform: translateY(-2px);
}

.submit-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Loading state styles */
.submit-btn.loading {
    position: relative;
    color: transparent;
}

.submit-btn.loading::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid var(--text-primary);
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Responsive Design */
@media (max-width: 968px) {
    .contact-content {
        grid-template-columns: 1fr;
    }

    .contact-info {
        padding: 0;
    }

    .info-card {
        max-width: 400px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .contact-container {
        padding: 2rem 1rem;
    }

    .contact-hero h1 {
        font-size: 2rem;
    }

    .category-btn {
        padding: 0.8rem 1.2rem;
        font-size: 0.9rem;
    }

    .contact-form {
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {
    .category-btn {
        width: 100%;
        justify-content: center;
    }

    .contact-categories {
        flex-direction: column;
        gap: 0.5rem;
    }
}

/* Toast Notification Styles */
.toast-notification {
    background: var(--background-secondary);
    border-radius: 8px;
    padding: 1rem;
    box-shadow: var(--shadow-sm);
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.toast-notification.success {
    border-left: 4px solid var(--accent-primary);
}

.toast-notification.error {
    border-left: 4px solid var(--accent-secondary);
}

.toast-notification svg {
    font-size: 1.2rem;
}

.toast-notification.success svg {
    color: var(--accent-primary);
}

.toast-notification.error svg {
    color: var(--accent-secondary);
}