.header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: var(--background-primary);
}

.navbar {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar__brand {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    text-decoration: none;
    transition: all 0.2s ease;
}

.navbar__brand:hover {
    opacity: 0.9;
}

.navbar__logo {
    width: 32px;
    height: auto;
    filter: drop-shadow(0 2px 4px var(--overlay-color));
}

.navbar__title {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--text-primary);
    letter-spacing: -0.025em;
    text-transform: uppercase;
    background: linear-gradient(135deg, var(--accent-primary), var(--accent-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.navbar__menu {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.navbar__link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 1rem;
    color: var(--text-secondary);
    text-decoration: none;
    font-weight: 500;
    font-size: 0.95rem;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.navbar__link:hover {
    color: var(--accent-primary);
    background: rgba(255, 107, 107, 0.04);
}

.navbar__link--active {
    color: var(--accent-primary);
    background: rgba(255, 107, 107, 0.04);
}

.navbar__link--cta {
    background: var(--accent-primary);
    color: var(--text-on-primary);
    padding: 0.625rem 1.25rem;
    box-shadow: var(--shadow-accent);
    border-radius: 15px;
}

.navbar__link--cta:hover {
    background: var(--accent-secondary);
    color: var(--text-on-primary);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(255, 107, 107, 0.2);
}

.navbar__link svg {
    font-size: 1rem;
}

/* Mobile styles */
.navbar__toggle {
    display: none;
    padding: 0.75rem;
    color: var(--text-primary);
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 1.75rem;
    z-index: 1001;
}

.navbar__mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background-primary);
    padding: 8rem 1.5rem 1.5rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1000;
}

.navbar__mobile--open {
    opacity: 1;
    visibility: visible;
}

.navbar__mobile-links {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    max-width: 400px;
    margin: 0 auto;
    height: 100%;
    justify-content: center;
}

.navbar__mobile .navbar__link {
    background: transparent;
    border-radius: 16px;
    padding: 1.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    transition: all 0.3s ease;
    justify-content: center;
    text-align: center;
    color: var(--text-primary);
}

.navbar__mobile .navbar__link:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
}

.navbar__mobile .navbar__link svg {
    font-size: 1.5rem;
}

.navbar__mobile .navbar__link--cta {
    background: var(--accent-gradient);
    color: var(--text-on-primary);
    margin-top: 1rem;
    box-shadow: var(--shadow-accent);
}

.navbar__mobile .navbar__link--cta:hover {
    background: var(--accent-gradient-hover);
    color: var(--text-on-primary);
    transform: translateY(-2px);
    box-shadow: 0 6px 25px rgba(255, 107, 107, 0.25);
}

.navbar__mobile-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--overlay-color);
    backdrop-filter: var(--backdrop-blur);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}



@media (max-width: 840px) {
    .navbar__menu {
        display: none;
    }

    .navbar__brand {
        z-index: 1001;
    }

    .navbar__toggle {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navbar__mobile {
        display: block;
    }

    .header {
        background: var(--background-primary);
    }

    .navbar {
        padding: 1.25rem 1.5rem;
    }

    .navbar__title {
        font-size: 1.4rem;
    }

    .navbar__logo {
        width: 36px;
        height: auto;
    }
}

@media (max-width: 480px) {
    .navbar__mobile .navbar__link {
        font-size: 1.3rem;
        padding: 1.25rem;
    }

    .navbar__mobile .navbar__link svg {
        font-size: 1.4rem;
    }

    .navbar {
        padding: 1rem 1.25rem;
    }

    .navbar__logo {
        width: 32px;
    }

    .navbar__title {
        font-size: 1.3rem;
    }

    .navbar__toggle {
        font-size: 1.5rem;
        padding: 0.75rem;
    }
}  