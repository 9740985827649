.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
    font-family: 'Inter', sans-serif;
}

.faq-header {
    text-align: center;
    margin-bottom: 3rem;
}

.faq-header h1 {
    font-size: 2.5rem;
    color: var(--text-quaternary);
    margin-bottom: 1rem;
}

.faq-header p {
    color: var(--text-tertiary);
    font-size: 1.1rem;
}

.faq-navigation {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3rem;
}

.category-button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    background: var(--button-background);
    color: var(--text-quinary);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.category-button:hover {
    background: var(--button-hover-background);
    color: var(--button-hover-color);
    transform: translateY(-2px);
}

.category-button.active {
    background: var(--button-hover-background);
    color: var(--button-hover-color);
}

.faq-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    background: var(--card-background);
    border-radius: 12px;
    padding: 0.5rem;
    box-shadow: var(--shadow-sm);
    transition: all 0.3s ease;
}

.faq-item:hover {
    box-shadow: var(--shadow-md);
}

.faq-item summary {
    padding: 1rem;
    cursor: pointer;
    font-weight: 600;
    color: var(--text-quaternary);
    list-style: none;
    position: relative;
    padding-right: 2.5rem;
}

.faq-item summary::-webkit-details-marker {
    display: none;
}

.faq-item summary::after {
    content: '+';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: var(--accent-primary);
    transition: transform 0.3s ease;
}

.faq-item[open] summary::after {
    transform: translateY(-50%) rotate(45deg);
}

.faq-item p {
    padding: 1rem;
    color: var(--text-quinary);
    line-height: 1.6;
    margin: 0;
    border-top: 1px solid var(--border-color);
}

.faq-footer {
    text-align: center;
    margin-top: 4rem;
    color: var(--text-primary);
    padding-top: 2rem;
    border-top: 1px solid var(--border-color);
}

.faq-footer a {
    color: var(--accent-primary);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.faq-footer a:hover {
    color: var(--accent-secondary);
}

@media (max-width: 768px) {
    .faq-container {
        padding: 2rem 1rem;
    }

    .faq-header h1 {
        font-size: 2rem;
    }

    .category-button {
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
    }
} 