.blog-container {
  min-height: 100vh;
  background: var(--background-primary);
  padding: 30px 10% 80px;
}

time {
  color: var(--text-secondary);
}

.blog-hero {
  text-align: center;
  margin-bottom: 4rem;
}

.blog-hero h1 {
  font-size: 4rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  font-weight: 800;
  color: var(--text-primary);
}

.blog-hero p {
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.blog-card {
  background: var(--background-secondary);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.blog-card:hover {
  transform: var(--card-hover-transform);
  box-shadow: var(--shadow-lg);
}

.blog-card-image {
  height: 240px;
  overflow: hidden;
}

.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card-content {
  padding: 2.5rem;
}

.blog-category {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: var(--accent-primary);
  color: var(--text-on-primary);
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.blog-card h2 {
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
  line-height: 1.3;
}

.blog-card p {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.blog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.blog-date {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.read-more {
  color: var(--accent-primary);
  text-decoration: none;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

/* Blog Post Styles */
.blog-post-container {
  margin: 0 auto;
  padding: 80px 2rem;
  border-radius: 16px;
  background: var(--background-primary);
}

.blog-post-mini-container {
  max-width: 800px;
  margin: 0 auto;
  background: var(--background-primary);
}

.blog-card-image.full {
  width: 100%;
  height: 400px;
  margin-bottom: 2rem;
  border-radius: 16px;
  overflow: hidden;
}

.blog-card-image.full img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-post-meta-top {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.blog-post-category {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: var(--accent-primary);
  color: var(--text-on-primary);
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.875rem;
}

.blog-post-not-found {
  text-align: center;
  padding: 80px 2rem;
  background: var(--background-primary);
  color: var(--text-primary);
}

.blog-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0;
}

.tag {
  padding: 0.5rem 1rem;
  background: var(--button-background);
  color: var(--text-secondary);
  border-radius: 20px;
  font-size: 0.875rem;
}

.blog-post-header {
  text-align: center;
}

.blog-post-header h1 {
  font-size: 2.5rem;
  color: var(--text-primary);
  line-height: 1.2;
  margin: 1rem 0;
}

.blog-post-author {
  margin: 2rem 0;
  padding: 1.5rem;
  background: var(--background-tertiary);
  border-radius: 12px;
}

.author-info {
  text-align: center;
}

.author-name {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  display: block;
}

.author-bio {
  font-size: 0.9rem;
  color: var(--text-secondary);
  line-height: 1.6;
}

.blog-post-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-secondary);
  padding: 2rem 0;
}

.share-section {
  margin: 3rem 0;
  text-align: center;
}

.blog-post-cta {
  text-align: center;
  margin: 3rem 0;
}

.blog-post-cta .cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background: var(--accent-primary);
  color: var(--text-on-primary);
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  text-align: center;
  box-shadow: var(--shadow-accent);
}

.blog-post-cta .cta-button:hover {
  background: var(--background-secondary);
  color: var(--accent-primary);
  border-color: var(--accent-primary);
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.share-section h3 {
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.share-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.share-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  background: var(--button-background);
  color: var(--text-secondary);
  border-radius: 8px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  gap: 0.5rem;
}

.share-button:hover {
  background: var(--background-secondary);
  border-color: var(--text-secondary);
  transform: translateY(-2px);
}

.blog-post {
  margin-top: 2rem;
}

.return-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--text-primary);
  color: var(--background-secondary);
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.95rem;
  margin: 1rem 0 2rem;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.return-button:hover {
  background: var(--background-secondary);
  color: var(--text-primary);
  border-color: var(--text-primary);
  transform: translateY(-2px);
}

/* Blog Filters Styling */
.blog-filters {
  max-width: 1200px;
  margin: 0 auto 3rem;
}

.categories {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.categories span {
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background: var(--background-secondary);
  color: var(--text-secondary);
  border: 2px solid transparent;
  box-shadow: var(--shadow-sm);
}

.categories span:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  border-color: var(--accent-primary);
  color: var(--accent-primary);
}

.categories span.active {
  background: var(--accent-primary);
  color: var(--text-on-primary);
  font-weight: 600;
}

.categories span.active:hover {
  background: var(--accent-secondary);
  border-color: transparent;
  color: var(--text-on-primary);
}

/* Blog Post Content Markdown Styles */
.blog-post-content {
  line-height: 1.6;
}

.blog-post-content h1,
.blog-post-content h2,
.blog-post-content h3 {

  color: var(--text-primary);
}

.blog-post-content h1 {
  font-size: 2.5rem;
}

.blog-post-content h2 {
  font-size: 2rem;
}

.blog-post-content h3 {
  font-size: 1.5rem;
}

.blog-post-content p {
  margin-bottom: 1.5em;
  color: var(--text-secondary);
}

.blog-post-content ul,
.blog-post-content ol {
  margin-bottom: 1.5em;
  padding-left: 2em;
  color: var(--text-secondary);
}

.blog-post-content li {
  margin-bottom: 0.5em;
}

.blog-post-content strong {
  font-weight: 600;
  color: var(--text-primary);
}

.blog-post-content blockquote {
  border-left: 4px solid var(--border-color);
  padding-left: 1em;
  margin: 1.5em 0;
  font-style: italic;
  color: var(--text-tertiary);
}

.blog-post-content a {
  color: var(--accent-primary);
  text-decoration: underline;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .blog-card{
    max-width: 90%;
  }

  .blog-grid{
    padding: 0;
  }

  .blog-container{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .blog-post-container {
    padding: 4rem 1.5rem;
  }

  .blog-post-header h1 {
    font-size: 2rem;
  }

  .blog-tags {
    gap: 0.5rem;
  }

  .tag {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  .return-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .share-buttons {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .share-button {
    flex: 1 1 calc(50% - 0.5rem);
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .blog-filters {
    margin-bottom: 2rem;
    padding: 0 1rem;
  }

  .categories {
    gap: 0.75rem;
  }

  .categories span {
    padding: 0.6rem 1.25rem;
    font-size: 0.9rem;
  }

  .blog-post-content h1 {
    font-size: 2rem;
  }

  .blog-post-content h2 {
    font-size: 1.75rem;
  }

  .blog-post-content h3 {
    font-size: 1.5rem;
  }
}