/* Code Display Styles */
.modern-code-display {
    font-family: var(--font-mono);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
    letter-spacing: var(--letter-spacing-tight);
    margin-bottom: 1rem;
  }
  
  .modern-code-display.with-header {
    margin-top: -1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 0.45rem 1.05rem;
    color: var(--text-light);
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .code-language.with-header {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }
  

.code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 0.45rem 1.05rem;
    color: var(--text-light);
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .code-language.with-header {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }
  
  .sticky-copy-button {
    position: sticky;
    top: 0.75rem;
    right: 1.25rem;
    z-index: 11;
    background-color: var(--background-main);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--text-light);
  }
  
  .sticky-copy-button:hover {
    background-color: var(--background-hover);
  }
  
  .code-language {
    text-transform: uppercase;
    color: var(--text-primary);
    font-family: var(--font-mono);
    font-weight: var(--font-weight-medium);
    letter-spacing: var(--letter-spacing-wide);
  }
  
  .code-display {
    padding: 1.25rem;
    font-size: 0.95rem;
    line-height: 1.6;
    color: var(--text-light);
    counter-reset: line;
    overflow-x: auto;
    font-weight: 500;
    font-feature-settings: "zero" 1, "ss01" 1, "ss02" 1;
    -webkit-font-variant-ligatures: contextual;
    font-variant-ligatures: contextual;
    white-space: pre-wrap !important;
    word-break: break-word;
    overflow-x: visible !important;
  }
  
  /* Syntax Highlighting */
  .code-display .token.comment,
  .code-display .token.prolog,
  .code-display .token.doctype,
  .code-display .token.cdata {
    color: var(--success-color);
    font-style: italic;
  }
  
  .code-display .token.punctuation { color: var(--text-light); }
  .code-display .token.property,
  .code-display .token.tag,
  .code-display .token.constant,
  .code-display .token.symbol,
  .code-display .token.deleted { color: var(--info-color); }
  .code-display .token.boolean,
  .code-display .token.number { color: var(--success-color); }
  .code-display .token.selector,
  .code-display .token.attr-name,
  .code-display .token.string,
  .code-display .token.char,
  .code-display .token.builtin,
  .code-display .token.inserted { color: var(--warning-color); }
  .code-display .token.operator,
  .code-display .token.entity,
  .code-display .token.url,
  .code-display .language-css .token.string,
  .code-display .style .token.string { color: var(--text-light); }
  .code-display .token.atrule,
  .code-display .token.attr-value,
  .code-display .token.keyword { color: var(--primary-light); }
  .code-display .token.function,
  .code-display .token.class-name { color: var(--warning-color); }
  .code-display .token.regex,
  .code-display .token.important,
  .code-display .token.variable { color: var(--info-color); }
  
  /* Line Numbers and Hover Effect */
  .code-display .line {
    display: inline-block;
    width: 100%;
    padding-left: 3.5em;
    position: relative;
    white-space: pre-wrap !important;
    word-break: break-word !important;
    padding-right: 1em;
  }
  
  .code-display .line::before {
    counter-increment: line;
    content: counter(line);
    position: absolute;
    left: 0;
    top: 0;
    width: 3em;
    text-align: right;
    color: var(--text-tertiary);
    opacity: 0.5;
    padding-right: 0.5em;
    border-right: 1px solid var(--sidebar-border);
  }
  
  .code-display .line:hover {
    background-color: var(--sidebar-hover);
  }
  
  /* Scrollbar Styles */
  .code-display::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .code-display::-webkit-scrollbar-track {
    background: var(--sidebar-bg);
  }
  
  .code-display::-webkit-scrollbar-thumb {
    background-color: var(--sidebar-border);
    border-radius: 4px;
  }
  
  .code-display::-webkit-scrollbar-thumb:hover {
    background-color: var(--sidebar-active);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .modern-code-display {
      font-size: 0.9rem;
    }
  
    .code-header {
      padding: 0.6rem 0.5rem;
    }
  
    .code-display {
      padding: 0.5rem;
    }
  
    .code-display .line {
      padding-left: 1.2em; /* Minimal space for line numbers */
    }
  
    .code-display .line::before {
      width: 1em;
    }
  
    .modern-code-display,
    .code-display {
      overflow-x: visible !important;
      white-space: pre-wrap !important;
    }
  }

  