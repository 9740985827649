a {
  text-decoration: none;
  color: var(--accent-primary);
}

a:hover {
  color: var(--accent-secondary);
}


img {
  pointer-events: none;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-primary);
}

.main-content {
  flex: 1 0 auto;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--background-quaternary);
  border-radius: 3px;
  transition: background 0.2s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-tertiary);
}