.policy-container {
    font-family: 'Inter', sans-serif;
    max-width: 1000px;
    margin: 4rem auto;
    margin-top: 1rem;
    padding: 3rem;
    background-color: var(--background-primary);
    border-radius: 24px;
    color: var(--text-quaternary);
}

.policy-header {
    text-align: center;
    margin-bottom: 3rem;
}

.policy-container h1 {
    color: var(--text-primary);
    font-size: 2.8rem;
    margin-bottom: 1rem;
    font-weight: 800;
}

.policy-meta {
    color: var(--text-tertiary);
    font-size: 0.95rem;
    margin-bottom: 2rem;
}

.policy-toc {
    background: var(--background-tertiary);
    padding: 2rem;
    border-radius: 12px;
    margin-bottom: 3rem;
}

.policy-toc h3 {
    color: var(--text-quaternary);
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
}

.policy-toc ul {
    list-style: none;
    padding: 0;
    margin: 0;
    columns: 2;
}

.policy-toc li {
    margin-bottom: 0.8rem;
    break-inside: avoid;
}

.policy-toc a {
    color: var(--text-quinary);
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.policy-toc a:hover {
    color: var(--accent-primary);
    transform: translateX(5px);
}

.policy-section {
    margin-bottom: 3rem;
    padding: 2rem;
    background: var(--background-secondary);
    border-radius: 16px;
    border: 1px solid var(--border-color);
    transition: all 0.3s ease;
}

.policy-section:hover {
    box-shadow: var(--shadow-sm);
    border-color: var(--accent-primary);
}

.policy-section h2 {
    color: var(--text-quaternary);
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
}

.policy-section h3 {
    color: var(--text-quaternary);
    font-size: 1.4rem;
    margin: 1.5rem 0 1rem;
    font-weight: 600;
}

.policy-section p {
    margin-bottom: 1.2rem;
    line-height: 1.8;
    color: var(--text-quinary);
}

.policy-section ul {
    list-style: none;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.policy-section li {
    margin-bottom: 0.8rem;
    position: relative;
    line-height: 1.6;
    color: var(--text-quinary);
}

.policy-section li::before {
    content: "•";
    color: var(--accent-primary);
    position: absolute;
    left: -1.5rem;
    font-weight: bold;
}

.policy-footer {
    text-align: center;
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid var(--border-color);
}

.policy-footer p {
    color: var(--text-tertiary);
    font-size: 0.95rem;
}

@media (max-width: 768px) {
    .policy-container {
        margin: 2rem 1rem;
        padding: 1.5rem;
    }

    .policy-container h1 {
        font-size: 2rem;
    }

    .policy-toc ul {
        columns: 1;
    }

    .policy-section {
        padding: 1.5rem;
    }

    .policy-section h2 {
        font-size: 1.5rem;
    }
}

/* Print styles */
@media print {
    .policy-container {
        box-shadow: none;
        margin: 0;
        padding: 2rem;
    }

    .policy-section {
        break-inside: avoid;
        border: none;
        padding: 0;
    }
}  